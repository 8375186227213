import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय १५ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय १५ वा' num='15'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे परमेश्वरा नारायणा । माझ्या मनाचे हेतू नाना । पूर्ण करण्यास करू नको ना ना ॥ १ ॥
तुझ्या होकारातच होते साकार । तूच भक्तांना वाटतो आधार । नको बनवू निराधार । तुझ्यावरच भरवसा ठेवणार ॥ २ ॥
एक देतो दुसरा रक्षितो । हे गणामहाराज जाणतो । तुला स्थानास येण्यास विनवितो । ग्रंथास सहाय्य करण्या विनवितो ॥ ३ ॥
गुरू तोषण्यात आनंद मिळतो । हे भक्तगण जाणतो । गुरू वेळोवेळी परिक्षा घेतो । उत्तीर्णास जवळ करतो ॥ ४ ॥
तू यावे माझ्या स्थानास । द्यावे भक्तांच्या परिक्षेस । प्रमाणपत्र द्यावे उत्तीर्णास । आनंद होईल भक्तांस ॥ ५ ॥
मी तोषीन भक्त होण्या उत्तीर्ण । तू जाण माझे मन । मी न मागणार अन्य । कर तू त्यांना लोकमान्य ॥ ६ ॥
येऊ दे संकट भक्तांना । तव कृपे होणार्‍या लोकमान्यांना । तुझ्यावाचुन न तारे त्यांना । हे माहित आहे भक्तांना ॥ ७ ॥
लोकमान्यांचे असेच झाले । त्यांच्यावरही संकट आले । समर्थ आशिष मदतरूप झाले । तेणे बाळ लोकमान्य झाले ॥ ८ ॥
ऐसे झाले प्रसंगास । शिवजयंती उत्सव अकोल्यास । बोलाविले बड्या बड्या नेत्यांस । आमंत्रिले लोकमान्यांस ॥ ९ ॥
शिवाजी सुपुत्र जिजामातेचा । सुपुत्र भारतमातेचा । प्राणाचीही पर्वा न करायचा । तिच्यासाठीच जगायचा ॥ १० ॥
शिवाजीची माता जिजाबाई । शूरवीरांचे संस्कार देई । पिता शहाजी परगावी । मोगलांच्या सेवेत राही ॥ ११ ॥
शिवाजी मूळचा शूर वीर । त्यात मातेच्या शिकवणीची भर । मावळे लाभले थोर । अनेक किल्ले केले सर ॥ १२ ॥
गनिमीकावे अजमावून । शत्रुंवर जरब बसवून । स्त्रियांची अब्रू रक्षून । परी कुणास न लुबाडून ॥ १३ ॥
समर्थ रामदास गुरू लाभले । जीवन प्रेरणामय झाले । ऐसे पराक्रम त्याने केले । इतिहासात पान अमर झाले ॥ १४ ॥
जो अमर राही नामाने । वागे ऐशा कृतीने । समाजासाठीच त्याचे जीणे । त्याचे उत्सव होती नेमाने ॥ १५ ॥
प्रेरणा मिळण्याच्या हेतूने । होती प्रेरणात्मक भाषणे । येतात वक्ते उत्साहाने । जे निमंत्रिले जातात प्रामुख्याने ॥ १६ ॥
शिवजयंती उत्सव अकोल्यास । वाटे बोलवावे लोकमान्यांस । द्यावे सणसणीत भाषणास । लोकांस प्रेरणा मिळण्यास ॥ १७ ॥
समर्थांस बोलवा सभेस । असे वाटले काही जणांस । रामदास छत्र शिवाजीस । तैसे समर्थ टिळकांस ॥ १८ ॥
काही फुटले मंडळीतले । दुसर्‍यांस ते म्हणू लागले । नको वेडा-पिसा करेल चाळे । जेणे सभा उधळे ॥ १९ ॥
आधीच उत्सव पहिला होईल । त्यात जर समर्थ येतील । तर गोंधळच होईल । तोच शेवटचा होईल ॥ २० ॥
नको समर्थांस आमंत्रण । नको उपाधीस आमंत्रण । होऊ फजीतीस कारण । न वठणार टिळकांचे भाषण ॥ २१ ॥
तो कसला आला योगी ? । तो तर पक्का ढोंगी । दारोदारी भीक मागी । योग्याचे लक्षण न त्याचे अंगी ॥ २२ ॥
नको नको ते सोंग येथे । उपाधीचे धेंड येथे । चालत्या गाडीत खीळ ते । तेणे येती नाना संकटे ॥ २३ ॥
जो तो टाळे उपाधीला । कोण पत्करणार तिला ? । समर्थ येता सभेला । गोंधळ होईल सभेला ॥ २४ ॥
जनांस न कळे गूढ । मूढांस वाटते दुसरा मूढ । जो समर्थांस म्हणे मूढ । तोच समजावा मूढ ॥ २५ ॥
जो अश्रद्धावान असणार । तो मूढ असणार । तो कधीही न सुधरणार । तो ऐसेच बोलणार ॥ २६ ॥
ज्यांनी समर्थांस नाकारले । तेच प्रसंगी मूढ ठरले । ज्यांनी त्यांना निमंत्रिले । तेच प्रसंगी योग्य ठरले ॥ २७ ॥
शेवटी आमंत्रण करणे ठरविले । दादासाहेब खापर्ड्यांस सोपविले । दादासाहेब शेगावी गेले । समर्थांचे चरण धरले ॥ २८ ॥
ऐसे न करणार आम्ही चाळे । जेणे सभा उधळे । खापर्डे चकित झाले । समर्थ अंतर्ज्ञानी पटले ॥ २९ ॥
अंतर्ज्ञानी पटतो प्रसंगाने । नच आत्मस्तुतीने । नुसत्या वाणी प्रभावाने । वा दुसरे कोणी सांगितल्याने ॥ ३० ॥
दिवस उजाडला सभेचा । पारावार न आनंदाचा । आनंद होता उत्सवाचा । नव्हे उत्सव होता आनंदाचा ॥ ३१ ॥
एकातच लाभे अनेक । जो तो सभेस उत्सुक । दूरदूरहुन आले कित्येक । भाषण प्रभावी तितकेच ॥ ३२ ॥
वाट पहात होते गजाननाची । एका समर्थाची । एका योग्याच्या लीलेची । एका सामर्थ्याची ॥ ३३ ॥
एका आशिर्वादाची । एका कृपादृष्टीची । सोबत असावी त्याची । हीच ईच्छा असते सर्वांची ॥ ३४ ॥
कृपादृष्टी असावी । कृती अमर ठरावी । ऐसी अपेक्षा असावी । ती योग्यच ठरावी ॥ ३५ ॥
टिळक मुळातच सोने । त्यात भर पाडावी गजानने । एका योग्याच्या सहवासाने । एका योग्याच्या आशिषाने ॥ ३६ ॥
सोने काय न लकाकणार ? । त्याचे तेज फाकणार । एका तेजात दुसरे तेज मिसळणार । तेजस्वी दैदिप्यमान वातावरण होणार ॥ ३७ ॥
सार्‍या जनांचे डोळे लागलेले  दोघांकडे । एक डोळा टिळकांकडे । दुसरा डोळा योग्याकडे । घ्यावया जीवनाचे धडे ॥ ३८ ॥
तैसेच होते कानांचे । विशेष श्रवण करण्याचे । लक्ष लागलेले सर्वांचे । चाहते होते दोघांचे ॥ ३९ ॥
टिळक उभे राहिले । भाषणास प्रारंभ केले । उत्सवाचे महत्व सांगितले । एकोप्याचे महत्व समजाविले ॥ ४० ॥
गर्दी वाढत होती । उत्स्फूर्त हजेरी होती । उत्सुकता शिगेला पोहोचली होती । विशेष ऐकण्याची वाट पहात होती ॥ ४१ ॥
टिळक ठासून बोलले । त्यांना न भान राहिले । इंग्रजांवर कोरडे ओढले । त्यासाठीच ते आलेले ॥ ४२ ॥
महाराजांना हे न खपले । पुढचे प्रसंग जाणले । त्यांने टिळकांना हटकले । समयसूचकता सुचवले ॥ ४३ ॥
कर्तबगारी नि देशभक्ती । बाणेदार वृत्ती नि कणखर वृत्ती । हरघडीस साथ होती । तीच प्रवृत्त करत होती ॥ ४४ ॥
टिळक बोलतच राहिले । पुढच्या प्रसंगास कारण बनले । सरकारने दोषी ठरविले । सरकारने खटले भरले ॥ ४५ ॥
महाराज चर्चेचे निमित्त बनले । नव्हे महाराजच कारणीभूत झाले । समर्थांचे सामर्थ्य जागे झाले । हे येथे प्रत्ययास आले ॥ ४६ ॥
सभा संपता श्रोते घरी गेले । टिळकांवर मात्र खटले भरले । नेहमीचेच झाले । टिळकांस विशेष न वाटले ॥ ४७ ॥
साथीदारांचे प्रयत्न चालले । नवस अंगार्‍याचेही प्रयत्न झाले । खापर्डे कोल्हटकरही निघाले । खापर्डे कोल्हटकरांस म्हणाले ॥ ४८ ॥
तुम्ही जावे शेगावास । समर्थांना साकडे घालण्यास । मी जातो मुंबईस । कोर्टाच्या कामास ॥ ४९ ॥
कोल्हटकर आले दर्शनाला । समर्थांचे आशिष घ्यायला । प्रत्येकाचा हेतू जरी वेगळा । परी एकच होते कृतीला ॥ ५० ॥
सवय होती महाराजांना । आशिर्वाद द्यावे भक्तांना । केव्हा पहातो महाराजांना । ऐसे झाले कोल्हटकरांना ॥ ५१ ॥
येथे एक प्रसंग घडला । कोल्हटकरांची परिक्षा घ्यायला । महाराज होते निद्रेला । उठेनात काही केल्या ॥ ५२ ॥
दोन दिवस तसेच गेले । तिसर्‍या दिवशीचेही तसेच झाले । महाराज काही न बोलले । कोल्हटकरांस तिष्ठत ठेवले ॥ ५३ ॥
परी कोल्हटकर चिकाटीचे होते । कळकळ व्यक्त करत होते । ते तेथेच बसले होते । आशिषावाचून जाणार नव्हते ॥ ५४ ॥
उजाडले चवथ्या दिवसास । समर्थ वदले कोल्हटकरांस । सरकार चालवेल खटल्यास । त्रास होईल टिळकास ॥ ५५ ॥
सज्जनांस त्रास दिल्यावाचून । राज्यक्रांतीचे न नाव निशाण । समाजास जागृत केल्यावाचून । सज्जन न राहे स्वस्थ बसून ॥ ५६ ॥
सरकार न ह्याला सोडणार । परी वचपा काढणार । जो सरकार विरूद्ध जाणार । त्याला शिक्षाच होणार ॥ ५७ ॥
कोल्हटकर निराश झाले । पुन्हा पुन्हा विनवू लागले । त्यावर समर्थ म्हणाले । त्याच्या भाग्यातलेच सांगितले ॥ ५८ ॥
परी देतो भाकरीच्या प्रसादाला । तू द्यावेस टिळकाला । जगी अमर करायला । हाच प्रसाद उपयोगाला ॥ ५९ ॥
कोल्हटकर निराशेने परतले । कोल्हटकर टिळकांस भेटले । सारे वृत्तांत सांगितले । भाकरीचा प्रसाद देते झाले ॥ ६० ॥
दीर्घ दृष्टीच्या टिळकांस पटले । नशिबातले पुढचे ताट दिसले । मन खंबीर केले । टिळक कोर्टात हरले ॥ ६१ ॥
तुरूंगवास घडले । तुरूंगात गीतारहस्य रचले । यावत्चंद्रदिवाकरौ अमर झाले । टिळक लोकमान्य झाले ॥ ६२ ॥
गुरू भोग नाही टाळत । परी मदतरूप सुसह्य करण्यात । नाना प्रकारचे भक्त भेटत । आपला हेतू सांगत ॥ ६३ ॥
कोल्हापूरचा श्रीधर गोविंद काळे । गरिबीने नाना विचार चाले । मॅट्रीक पास झाले । इंटर नापास झाले ॥ ६४ ॥
वृत्तपत्र वाचत राहिले । नोकरीच्या शोधात राहिले । ओयोमा टोगोचे चरित्र वाचले । जपानचे विचार सुरू झाले ॥ ६५ ॥
जपानला जावे । यंत्रविद्येचे शिक्षण घ्यावे । भरपूर पैसे मिळवावे । आपले दारिद्र्य दूर करावे ॥ ६६ ॥
एकदा अकोल्याला । मित्रास भेटण्या गेला । परतताना शेगावी उतरला । समर्थांचे दर्शन घ्यायला ॥ ६७ ॥
समर्थ चरणी माथा ठेवला । कृपाशिष मागू लागला । समर्थे हेतू ओळखला । बोलले श्रीधर काळ्याला ॥ ६८ ॥
जाऊ नकोस तू विलायतेला । उच्च शिक्षण घ्यायला । येथे न कमी पडणार तुला । भाग्य येईल उदयाला ॥ ६९ ॥
समर्थांचे बोल खरे ठरले । श्रीधर एम्. ए. झाले । कॉलेजचे प्रिन्सिपॉल झाले । श्रीधर काळे उदयास आले ॥ ७० ॥
गुरूवर ठेवलेल्या निष्ठेस । काही न कमी पडले श्रीधरास । भाग्य आले उदयास । तैसेच येवो सर्व भक्तांस ॥ ७१ ॥
 
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य पंचदशोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
